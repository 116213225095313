import React from 'react';
import Slider from 'react-slick';
import Link from 'next/link';
// import investor from '@/public/images/tickets/investorTicket.png';
import februarie from '@/public/images/art/24Februarie.png';
import foculnegru from '@/public/images/art/foculNegru.png';
//import icoholder from '@/public/images/art/icoHolder.png';
import individualism from '@/public/images/art/individualism.png';
import multiplex from '@/public/images/art/multipleX.png';
import urmeleTrecutului from '@/public/images/art/urmeleTrecutului.png';
import sweetdeath from '@/public/images/art/sweetdeath.png';
import roataSoarele from '@/public/images/art/roataSoarele.png';
import sadPuppy from '@/public/images/art/sadpuppy.png';
import cerbul from '@/public/images/art/cerbul.png';
import Image from 'next/image';

export function HomeSlider() {
    return (
        <Slider
            className="cursor-grab active:cursor-grabbing w-[calc(100%-80px)] mx-auto"
            dots
            arrows
            autoplay
            infinite
            speed={750}
            slidesToShow={2}
            autoplaySpeed={4000}
        >
            {/* <div className={'dark:text-white'}>
                <Link href="https://icoholder.com/en/a-nft-world-1071376">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={icoholder.src}
                        alt={'ICO Holder'}
                    />
                </Link>
            </div> */}
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={foculnegru.src}
                        alt={'Dan Iulian Toma - Focul Negru'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={cerbul.src}
                        alt={'Constantin Popovici - Cum doreste un cerb izvoarele de apa'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={roataSoarele.src}
                        alt={'Dana Liliana Bredeanu - Roata/Soarele'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={multiplex.src}
                        alt={'Multiple X'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={februarie.src}
                        alt={'Regina Damian - 24 Februarie'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={sweetdeath.src}
                        alt={'Claudiu Adrian Toma - Sweet Death'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={urmeleTrecutului.src}
                        alt={'Costin Brateanu - Urmele Trecutului'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={sadPuppy.src}
                        alt={'Madalin Poenar - Sad Puppy'}
                    />
                </Link>
            </div>
            <div className={'dark:text-white'}>
                <Link href="/marketplace">
                    <Image
                        width={800}
                        height={500}
                        className={'w-full object-cover max-w-[1000px] mx-auto'}
                        src={individualism.src}
                        alt={'Zoltan Benedek Ors - Individualism'}
                    />
                </Link>
            </div>

            {/* ====== Add video to carousel ======== */}
            {/* <div className={'dark:text-white'}>
                <iframe
                    className={'aspect-video h-auto max-w-[80vw] lg:max-w-[800px] mx-auto'}
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/Z2pXkNi6qak?si=g0QgDxez37edHs7O"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div> */}
        </Slider>
    );
}
